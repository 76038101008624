
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Col from 'components/Col'
import Spinner from 'components/Spinner'

const Home = () => {
  const router = useRouter()

  useEffect(() => {
    router.replace('/explore')
  }, [router])

  return (
    <Col centerAll wid='100%' ht='100vh' noFlex>
      <Spinner />
    </Col>
  )
}

 async function _getServerSideProps({ req, resolvedUrl }) {
  const metaTags = {
    'og:title': 'Trally | Explore',
    'og:description': 'Explore Trally!',
    'og:image':
      'https://trally-static-data.s3.ap-southeast-1.amazonaws.com/trally-default-preview.png',
    'og:url': `${req.headers.host}${resolvedUrl}`
  }

  return {
    props: {
      metaTags
    }
  }
}

export default Home


    export async function getServerSideProps(ctx) {
        let res = _getServerSideProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              
            }))
          }
        }
    }
  